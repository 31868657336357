import { displayProductCardName } from "../../helpers/products"
import { priceFormat } from "../../helpers"
import moment from "moment"

export const ResultsTableRut = ({ data, handleValue, selectedNV, details }) => {
  return (
    <article className="self-management-results-table-container">
      <h3 className="self-management-results-table-title">LISTADO DE NOTAS DE VENTA</h3>

      <table className="self-management-results-table">
        <tbody>
          {data?.length
            ? data.map(({
              CLIENTE_NOMBRE,
              ORDER_NUM,
              agendamiento, nv
            }, i) => (
              <tr key={`${i}-${ORDER_NUM}-${CLIENTE_NOMBRE ?? 'usuario'}`}>
                <td>
                  <div
                    className={`self-management-table-info ${nv?.ORDER_NUM === selectedNV?.nota_venta && i === selectedNV?.index ? 'selected' : ''}`}
                    onClick={() => handleValue({ nota_venta: nv?.ORDER_NUM, index: i })}
                  >
                    <div className="self-management-table-data data">
                      <span>Pedido: <b>{nv?.EXT_NUM ?? '-'}</b></span>
                      <span>Nota Venta: <b>{nv?.ORDER_NUM ?? '-'}</b></span>
                      <span>Centro de servicio: <b>{`${nv?.SHIP_NAME_TEXT ?? '-'}, ${nv?.SHIP_ADDR_TEXT ?? 'Desconocido'}`}</b></span>
                    </div>

                    <div className="self-management-table-agenda agenda">
                      {agendamiento
                        ? <div className="self-management-detailed-nv-agenda-data">
                          <h4>Agenda</h4>
                          <span>{moment(agendamiento?.fecha).format('DD/MM/YYYY')}</span>
                          <span>{agendamiento?.hora_agenda?.hora_inicio_intervalo ?? '-'}</span>
                        </div>

                        : <b className="agenda-title none">Sin agenda</b>
                      }
                    </div>

                    <div className="self-management-table-details details">
                      {nv?.ORDER_NUM === selectedNV?.nota_venta &&
                        i === selectedNV?.index &&
                        <>
                          {details ?
                            <div className="self-management-table-detailed">
                              <table>
                                <thead>
                                  <tr>
                                    <th>Artículo</th>
                                    <th>Cantidad</th>
                                  </tr>
                                </thead>

                                <tbody>
                                  {details?.lineas?.length &&
                                    details.lineas.map(p => (
                                      <tr key={`linea-${p.id}`}>
                                        <td>{displayProductCardName(p.DESC_TEXT)}</td>
                                        <td>{p.ORDER_QTY}</td>
                                      </tr>
                                    ))}
                                </tbody>
                              </table>

                              <div className="self-management-table-detailed footer">
                                <span>Estatus: <b>{details?.pedido?.neu_estatus_pedido?.nombre ?? '-'}</b></span>
                                <span>Total productos: <b>{details?.pedido?.total_productos ?? '-'}</b></span>
                                <span>Total: <b>{priceFormat(details?.lineas?.reduce((prev, { LINE_TOT_AMT }) => (prev += Number(LINE_TOT_AMT)), 0)) ?? '-'}</b></span>
                              </div>
                            </div>

                            : <div className="self-management-table-detailed loading">Cargando detalles...</div>
                          }
                        </>
                      }
                    </div>
                  </div>
                </td>
              </tr>)
            )

            : <tr><td colSpan={3} className="no-data">No se encontraron resultados para su búsqueda</td></tr>
          }
        </tbody>
      </table>
    </article >
  )
}